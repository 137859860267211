import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

import {Header} from "./Header";
import {Footer} from "./Footer";

export const Layout = ({ children }:PropsWithChildren):React.ReactElement => (
  <>
    <Helmet>
      <script src="https://gumroad.com/js/gumroad.js" />
    </Helmet>
    <Header />
    <div>
      <main>{children}</main>
    </div>
    <Footer />
  </>
);

