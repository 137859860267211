import React from "react"
import { Layout } from "../../layout"
import { SecondaryColor } from "../../ux/colors"
import { Eyebrow } from "../../components/Eyebrow"
import ClinkArrow from "../../images/svgs/svg1.svg"
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import WeddingPic from "../../images/blogs-pictures/weddingpic.webp";

const SERVICE_ID = "service_mb9j2or";
const TEMPLATE_ID = "template_jqwqwvl";
const PUBLIC_KEY = "dwn2MSnCF3SN4auAM";

export const WeddingsBlog = (): React.ReactElement => {

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then((result) => {
        console.log(result.text);
        Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
        })
        }, (error) => {
        console.log(error.text);
        Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
        })
        });
    e.target.reset()
  };

  return(
    <Layout>
      <div className="container mx-auto flex justify-center">
        <div className="flex flex-col items-top gap-6 py-20 w-4/5">
          <h1 className="font-display md:text-display-lg sm:text-display-md text-display-sm text-primary-600 mb-8">
            Crafting the Perfect Wedding Bar: 5 Must-Have Items
          </h1>
          <div className="flex justify-center">
            <img src={WeddingPic} className="lg:w-2/3 w-full my-8 rounded-lg shadow-md" alt="Beautifully decorated wedding bar setup"/>
          </div>
          <p className="font-display md:text-display-xs text-display-xs">
            Wedding celebrations should feature open and enjoyable libations. Here are the key requirements for a perfect bar service:
          </p>
          <ul className="flex flex-col gap-4 mb-12">
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Variety of Beverages:</span> Ensure a diverse selection of alcoholic and non-alcoholic drinks to
                  cater to all guests&#39; preferences, including beer, wine, spirits, and soft drinks.
                </p>
            </li>
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Glassware:</span> Stock up on appropriate glassware for serving drinks, including wine glasses,
                  champagne flutes, highball glasses, and rocks glasses, to enhance the drinking experience.
                </p>
            </li>
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Ice and Cooling Equipment:</span> Maintain drinks at the perfect temperature by providing ample
                  ice and utilizing cooling equipment such as ice buckets, coolers, and ice machines.
                </p>
            </li>
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Garnishes and Accessories:</span> Enhance the presentation and flavor of drinks with an array of
                  garnishes such as citrus slices, cocktail olives, cherries, and cocktail picks, along with napkins,
                  straws, and stirrers for added convenience.
                </p>
            </li>
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Experienced Bar Catering Team and Staff:</span> By hiring Clink Bar Services, you eliminate the
                  stress of assembling a perfect wedding bar. Our team of wedding catering professionals will
                  create a package fit for your event, attendance, and preferences. We match our bar setup with
                  insured bartenders. We take time to understand your wedding needs and allocated budgets. Fill
                  out our brief questionnaire to receive a customized bar package for your special day.
                </p>
            </li>
          </ul>
          {/* <p className="font-display md:text-display-xs text-display-xs"> Text right here</p> */}
          <Eyebrow label="CONTACT US"/>
          <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
            Get In Touch With <span className="italic">Our Team</span>
          </h3>
          <div className="container flex flex-col items-center w-full">
            <form onSubmit={handleOnSubmit} className="flex flex-col items-center mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:px-6">
              <input type="hidden" name="form-name" value="clink-contact-us-form" />
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-2">
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="name">
                      Name
                      <input type="text" id="name" name="name" className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" placeholder="Name" required />
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="email">
                      Email
                      <input type="email" className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" id="email" name="email" placeholder="Enter your email address" required/>
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="phone">
                      Phone
                      <input type="tel" className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="phone" id="phone" placeholder="Enter your phone number" required />
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="date">
                      Date
                      <input type='date' className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="date" id="date" />
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="location">
                      Location
                      <input className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="location" id="location" placeholder="Enter event's location"/>
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="attendance">
                      Estimated Attendance
                      <input className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="attendance" id="attendance" placeholder="Enter the estimated attendance" required />
                    </label>
                </div>
              </div>
              <button type="submit" className="mt-6 inline-block w-3/6 rounded px-6 py-2.5 font-medium uppercase leading-normal hover:shadow-md hover:bg-teal-500
                button flex gap-1 items-center justify-center bg-primary-600 text-white font-semibold px-6 py-4 text-body-sm">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
)}