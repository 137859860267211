import React, { Suspense } from "react";
import { Layout } from "../layout";
import { HeroSection } from "./sections/HeroSection"; // Load HeroSection immediately

// Lazy load non-critical sections
const SeoSection = React.lazy(() => import("./sections/SeoSection"));
const AboutSection = React.lazy(() => import("./sections/AboutSection"));
const ServicesSection = React.lazy(() => import("./sections/ServicesSection"));
const OurProjectsSection = React.lazy(() => import("./sections/OurProjectsSection"));
const BlogsSection = React.lazy(() => import("./sections/BlogsSection"));
const ContactUsSection = React.lazy(() => import("./sections/ContactUsSection"));


const IndexPage = () => (
  <Layout>
    {/* Render HeroSection immediately */}
    <HeroSection />

    {/* Render other sections lazily */}
    <Suspense fallback={<div>Loading...</div>}>
      <SeoSection />
      <AboutSection />
      <ServicesSection />
      <OurProjectsSection />
      <BlogsSection />
      <ContactUsSection />
    </Suspense>
  </Layout>
);

export default IndexPage;
