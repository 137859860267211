import { cloudinary } from "../../config/cloudinaryConfig";
import { AdvancedImage } from '@cloudinary/react';

export const HeroSection = ():React.ReactElement => {
  // const data = useStaticQuery(graphql`
  //   {
  //     heroimage: file(relativePath: { eq: "hero-image.png" }) {
  //       childImageSharp {
  //         gatsbyImageData(
  //           width: 5120
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //   }
  // `);
  return (
    <div id="hero">
      <div className="container mx-auto">
        <div className="flex flex-col xl:px-32 items-center text-center gap-6 py-20">
          <h1 className="font-display md:text-display-2xl text-display-lg">
          Elevate Your Event <span className="italic">Experiences</span>
          </h1>
          <h2 className="font-display md:text-display-md text-display-md">
          Crafters of Unforgettable Moments
          </h2>
          <p>
          Customizable Bar & Food Catering - Licensed Bartending Team - Event Management 
          </p>
          <p>
          Let’s Bring Your Dream Event To Life 
          </p>
        </div>
      </div>
      <div className="relative">
        <AdvancedImage className="h-auto max-w-full" cldImg={cloudinary.image('clink/hero-image_fd8k2t')} alt="welcome"/>
        <a href="/">
          <AdvancedImage
            cldImg={cloudinary.image('clink/cta-button_iplez4')}
            alt="Get in touch"
            className="absolute xl:left-28 lg:left-[44%] md:left-[42%] left-[35%] -top-16"
          />
        </a>
      </div>
    </div>
  );
};
