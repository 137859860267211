import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { WeddingsBlog } from "./pages/blogs/WeddingsBlog"
import { NeededKnowledge } from "./pages/blogs/NeededKnowledge"
import { UnforgettableParty } from "./pages/blogs/UnforgettableParty"
import ReactGA from 'react-ga';

ReactGA.initialize('G-S69GLLGKDC');

const App: React.FC = () => {
  /*const location = useLocation();
  
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  */

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Home />} />
        <Route path="/blogs/weddings" element={<WeddingsBlog />} />
        <Route path="/blogs/neededKnowledge" element={<NeededKnowledge />} />
        <Route path="/blogs/unforgettableParty" element={<UnforgettableParty />} />
        <Route path="*" element={<Home />} /> {/* This will catch anything that doesn't match the above routes */}
      </Routes>
    </Router>
  );
}

export default App;
