import { useState } from "react";
import { ContactButton } from "../components";
import Logo from "../images/logos/ClinkLogo.png";
import { Link } from 'react-router-dom';
import {
  XMarkIcon,
  Bars3Icon,
} from "@heroicons/react/24/solid";
// import {
//   Collapse,
// } from "@material-tailwind/react";

export const Header = ():React.ReactElement => {
  const navigation = [
    { name: "About", href: "/#about" },
    { name: "Services", href: "/#services" },
    { name: "Events", href: "/#events" },
    { name: "Blogs", href: "/#blogs" },
  ];

  const [isOpen, setIsOpen] = useState(false)

  return (
    <header className="sticky top-0 z-50 bg-white">
      <div className="container mx-auto">
        <div className="flex py-5 justify-between items-center">
          <div className="flex flex-row gap-8 items-center">
            <Link to="/">
              <img className="h-20 w-auto" src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="flex flex-row gap-6">
            <div className="md:flex hidden flex-row gap-4 items-center">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4">
                  {item.name}
                </a>
              ))}
              <ContactButton />
            </div>
            {isOpen ? (
              <XMarkIcon strokeWidth={2} className="h-6 w-6 text-primary-600 font-semibold md:hidden" onClick={() => setIsOpen(!isOpen)}/>
            ) : (
              <Bars3Icon strokeWidth={2} className="h-6 w-6 text-primary-600 font-semibold md:hidden" onClick={() => setIsOpen(!isOpen)}/>
            )}
          </div>
          {isOpen && <div className="md:hidden fixed bg-white top-28 left-0 right-0 z-50">
            <div className="flex justify-end me-6">
              <div className="flex flex-col gap-1 items-center me-4">
                {navigation.map((item) => (
                  <a
                    onClick={() => setIsOpen(false)}
                    key={item.name}
                    href={item.href}
                    className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4">
                    {item.name}
                  </a>
                ))}
                <div className="mb-2" onClick={() => setIsOpen(false)}>
                  <ContactButton />
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </header>
  );

  // return (
  //   <header className="sticky top-0 z-50 bg-white">
  //     <div className="container mx-auto">
  //       <div className="flex py-5 justify-between items-center">
  //         <div className="flex flex-row gap-8 items-center">
  //           <Link to="/">
  //             <img className="h-20 w-auto" src={Logo} alt="Logo" />
  //           </Link>
  //         </div>
  //         <div className="flex flex-row gap-6">
  //           <div className="md:flex hidden flex-row gap-4 items-center">
  //             {navigation.map((item) => (
  //               <a
  //                 key={item.name}
  //                 href={item.href}
  //                 className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4">
  //                 {item.name}
  //               </a>
  //             ))}
  //             <ContactButton />
  //           </div>
  //           {isOpen ? (
  //             <XMarkIcon strokeWidth={2} className="h-6 w-6 text-primary-600 font-semibold md:hidden" onClick={() => setIsOpen(!isOpen)}/>
  //           ) : (
  //             <Bars3Icon strokeWidth={2} className="h-6 w-6 text-primary-600 font-semibold md:hidden" onClick={() => setIsOpen(!isOpen)}/>
  //           )}
  //         </div>
  //         {isOpen && <Collapse open={isOpen} className="md:hidden fixed bg-white top-28 left-0 right-14 z-50">
  //           <div className="flex justify-end me-6">
  //             <div className="flex flex-col gap-1 items-center me-4">
  //               {navigation.map((item) => (
  //                 <a
  //                   key={item.name}
  //                   href={item.href}
  //                   className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4">
  //                   {item.name}
  //                 </a>
  //               ))}
  //               <div className="mb-2">
  //                 <ContactButton />
  //               </div>
  //             </div>
  //           </div>
  //         </Collapse>
  //         }
  //       </div>
  //     </div>
  //   </header>
  // );
};

export default Header;
